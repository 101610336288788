import { Datagrid } from "@empreinte/components.datagrid";
import { GridNorows } from "@empreinte/components.grid-norows";
import { useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { useWindowSize } from '../../../../utils/resizeEvent';
import { setCheckBoxDelete } from '../../store/canalActions';
import UseActionMenu from "./actionsUserTable";

export default function DataGridDemo({dataSource,setCurrentPage,currentPage,setPageSize,pageSize}:any) {
const {t} = useTranslation();
    const windowSize = useWindowSize()
    
    const showPhoneColumns = useMediaQuery('(min-width:757px)');
    const showmobile = useMediaQuery('(min-width:1000px)');
    const columns:any = [
        {
            headerName: 'ID',
            field: 'id',
            key: 0,
            flex:1,
            align: 'center',
            minWidth:30,
            maxWidth:100,
            sortable:false,
            headerAlign:'center',
            
        },
        {
            headerName: t('classement.Title'),
            field: 'Title',
            key: 1,
            align: 'center',
            flex:1,
            minWidth:50,
            width:showmobile ? 300 : 100,
            maxWidth:400,
            sortable:false,
            headerAlign:'center',

        },
        {
            headerName: t("classement.Language"),
            field: 'Language',
            key: 2,
            align: 'center',
            flex:1,
            minWidth:30,
            maxWidth:310,
            sortable:false,
            headerAlign:'center',
         
        },
        {
            headerName: 'Quotas (Mo)',
            field: 'Quota',
            key: 3,
            minWidth:80,
            align: 'center',
            flex:1,
            maxWidth:400,
            sortable:false,
            headerAlign:'center',

        },
    ];
    const updatedColumns = [
        ...columns,
        {
            headerName: 'Actions',
            key: 'action',
            flex:1,
            sortable:false,
            align: 'center',
            minWidth:windowSize ? 80 : 100,
            with:windowSize ? 80 : 150 ,
            headerAlign:'center',
            
           renderCell: (action: any, record: any) => {
           return (
                    <UseActionMenu record={action.row} />
                )
            }
        },
    ];
    const [source, setSource] = useState([])
    useEffect(() => {
        const list = dataSource?.content?.map((row: any) => {
            let arrayOfnodes: any = [];
            arrayOfnodes = ({ ...row.node,id:row.node.Id, servers: Array.from(row.node?.Servers, ({ Name }) => Name) })
            arrayOfnodes.Language = arrayOfnodes.Language === "ar" 
            ? t("classement.Arab")
            :arrayOfnodes.Language === "fr" 
            ? t("classement.French")
            : arrayOfnodes.Language === "en" 
            ? t("classement.English")
            : ""
            return arrayOfnodes
        })
        setSource(list)
    }, [dataSource.content]);

    const dispatch = useDispatch();
    const selectedRowsChange = (selectedRows:any)=>{
        dispatch(setCheckBoxDelete(selectedRows));
    }

  return (
    <>
        {source?.length ===0 && currentPage === 1 ?  <GridNorows content={t("classement.No matching records found")} /> :
        <Datagrid
            rows={source?.length ? source : []}
            columns={showPhoneColumns ? updatedColumns : updatedColumns.filter(el=> el.field!=="Quota" && el.field!=="servers" )}
            checkboxSelect={selectedRowsChange}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            totalEntities={dataSource?.totalElements}
            EntriesName={`${t("classement.entries")}`}
            height={"calc(100vh - 150px)"}
        />
        }
    </>
  );
}

