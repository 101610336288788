import "./theme.scss";
import ThemeTable from "./components/List/themeTable";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import i18next from "i18next";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import { Hooks } from "./utils/hooks";
import { getUserData } from "../../userData/userActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import PagenotFound from "../pagenotfound/404notFound";
import LoadingScreen from "../../components/LoadingScreen";
import { Helpers } from "../../utils/helpers";

const Theme = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { handleAddTheme } = Hooks();
  const { search } = useLocation();
  const { acces_send } = Helpers();
  const obj = new URLSearchParams(search);
  useEffect(() => {
    const lang = obj.get("lang");
    if (lang === "en" || lang === "fr") {
      i18next.changeLanguage(lang);
    }
  }, [obj.get("lang")]);
  const redirectThemesAdd = () => {
    acces_send("parameters_theme_post_add_action");
    handleAddTheme();
  };
  // loading + user token verification
  const isAuth = useAppSelector((state) => state.userReducer.isAuth);
  const loading = useAppSelector((state) => state.userReducer.loading);
  useEffect(() => {
    dispatch(getUserData());
  }, []);
  if (loading) return <LoadingScreen />;
  else if (!isAuth) return <PagenotFound />;
  return (
    <div className={"divGlobalTable"}>
      <Header
        onMainPage={true}
        isEdit={false}
        title={t("classement.theme")}
        firstBtnTitle={t("Profile.add")}
        mainPageBtnAction={() => {
          redirectThemesAdd();
        }}
      />
      <ThemeTable />
    </div>
  );
};

export default Theme;
