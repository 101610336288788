import React, { useEffect, useState } from "react";
import VignetteUpload from "./vignetteUpload";
import { useTranslation } from "react-i18next";
import { Hooks } from "../../utils/hooks";
import { useAppSelector } from "../../../../redux/hooks";
import { FormControl, MenuItem } from '@mui/material';
import { object, string } from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import { setChannels, setIdFromParams } from "../../store/themeActions";
import { GraphQLFetchData } from '../../utils/graphQLFetchData'
import { GraphQLFetchData as GraphQLFetchDataChannel} from "../../../canal/utils/graphQLFetchData";
import axios from "axios";
import { setSubmit } from "../../../canal/store/canalActions";
import { TextArea } from "@empreinte/components.text-area"
import { InputText } from '@empreinte/components.input-text'
import Header from "../../../../components/Header";
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { setAddEditLoader } from "../../../theme/store/themeActions";
import { SelectList } from "@empreinte/components.select-list"
import { SelectMultiple } from '@empreinte/components.select-multiple'
import { Loader } from "@empreinte/components.loader"
import "../../theme.scss"

const AddEditTheme = () => {
  const {GetListChannels } = GraphQLFetchDataChannel()
  const params = useParams()
  const dispatch = useDispatch()
  const [data, setChannelsdata] = useState<any>([])
  const { GetThemeById } = GraphQLFetchData()
  const { values, onValueFormUserchangeTheme,getThemesList,onResetTheme  } = Hooks()
  const channelList = useAppSelector((state:any) => state.CanalReducer.channelList)
  const {createTheme,updateTheme} = GraphQLFetchData()
  const initalValues = {
    Title: values.Title,
    description: values.description,
    classifiedBy: values.classifiedBy,
    visible: values.Visible,
    WebtvChannelIDs: values.WebtvChannelIDs
  }
  useEffect(()=>{
    let nodes :(any)[]
    nodes=[];
    channelList?.edges?.map((el : any)=>nodes?.push(el.node))
    dispatch(setChannels(nodes))
    setChannelsdata(nodes)
  },[channelList.length])
    
  useEffect(() => {
    getThemesList({variables:{pagination:{offset:0,limit:0}}})
    const getChannels = async() =>{
      await GetListChannels({variables:{pagination:{limit:0,offset:0}}})
    }
    getChannels()
    if (params.id && !Number.isNaN(+params.id)) {
      GetThemeById({ variables: { id: Number(params.id) } })
      dispatch(setIdFromParams(params.id))
    }
  }, [dispatch, params.id])

  const [Title, setTitle] = useState(values.Title)
  const MAX_TITLE_LENGTH = 255;
  
    const handleCreateNewTitle = (e: any) => {
    const inputTitle = e.target.value;
    if (inputTitle.length <= MAX_TITLE_LENGTH) {
      setTitle(inputTitle);
      onValueFormUserchangeTheme(inputTitle, 'Title');
    } else {
      e.preventDefault();
    }
  };

  const [Parent_id, setTheme] = useState(values.Parent_id)
  const handleCreateNewTheme = (e: any) => {
    e.preventDefault();
    setTheme(e.target.value)
  }
  const [description, setDescription] = useState(values.description)
  const MAX_DESCRIPTION_LENGTH = 255;

  const handleCreateNewDescription = (e: any) => {
    const inputDescription = e.target.value;
    if (inputDescription.length <= MAX_DESCRIPTION_LENGTH) {
      setDescription(inputDescription);
      onValueFormUserchangeTheme(inputDescription, 'description');
    } else {
      e.preventDefault();
    }
  };

  const [visible, setVisibility] = useState(false)
  const handleCreateVisibility = (e: any) => {
    e.preventDefault();
    setVisibility(e.target.value)
  }
  const { t } = useTranslation();
  const themes = useAppSelector((state: any) => state.ThemeReducer.themes)
  const channels: any = useAppSelector((state: any) => state.ThemeReducer.channels)
  const loaderStatus = useSelector((state: any) => state.ThemeReducer.addEditLoader)
  const title = useSelector((state: any) => state.ThemeReducer.addThemeConstraintsData.Title);
  const WebtvChannelIDs = useSelector((state: any) => state.ThemeReducer.addThemeConstraintsData.WebtvChannelIDs);
  const addThemeConstraintsData = useSelector((state: any) => state.ThemeReducer.addThemeConstraintsData)
  useEffect(() => {
    if (title.length < 1 || WebtvChannelIDs.length < 1 || addThemeConstraintsData.description.length < 1) {
      dispatch(setSubmit(false));

    }
    if (title.length > 0 && WebtvChannelIDs.length > 0 && addThemeConstraintsData.description.length > 0) {
      dispatch(setSubmit(true));

    }

  }, [WebtvChannelIDs, title, addThemeConstraintsData.description])
  const themeInputValues = useAppSelector((state: any) => state.ThemeReducer.addThemeConstraintsData)
  const isAbleToSubmit = useAppSelector((state: any) => state.CanalReducer.isAbleToSubmit);
  const WebtvChannel = useAppSelector((state: any) => state.ThemeReducer.WebtvChannel)

  const handleCreateTheme = async () => {
    dispatch(setAddEditLoader(true))
    const { Title, Logo, description, Directory, classifiedBy, Visible, Parent_id, Orderr, WebtvChannelIDs } = themeInputValues
    const dataTosend = { Title, Logo, description, Directory, classifiedBy, Visible, Parent_id, Orderr, WebtvChannelIDs }
    if (!Parent_id) delete dataTosend.Parent_id
    await createTheme({ variables: { input: dataTosend } })
    onResetTheme()
    dispatch(setSubmit(false));
  }

  const editTheme = () => {
    const WebtvChannelArr = WebtvChannel.map((el: any) => el.Id)
    const changedChannelList = themeInputValues.WebtvChannelIDs
    const AddWebtvChannelIDs: number[] = []
    const RemoveWebtvChannelIDs: number[] = []
    changedChannelList?.forEach((id: number) => {
      if (WebtvChannelArr.includes(id)) return
      else if (!WebtvChannelArr.includes(id)) {
        AddWebtvChannelIDs.push(id)
      }
    });
    WebtvChannelArr?.forEach((id: number) => {
      if (changedChannelList.includes(id)) return
      else if (!changedChannelList.includes(id)) {
        RemoveWebtvChannelIDs.push(id)
      }
    });
    delete themeInputValues.WebtvChannel
    delete themeInputValues.WebtvChannelIDs
    delete themeInputValues.Parent
    if (themeInputValues.description === "") delete themeInputValues.description
    updateTheme({ variables: { input: { ...themeInputValues, AddWebtvChannelIDs, RemoveWebtvChannelIDs } } })
    onResetTheme()
  }

  const location = useLocation();
  const checkPath = (pageInfo: string) => {
    return location.pathname.toLowerCase().includes(pageInfo.toLowerCase())
  }
  const navigate = useNavigate();
  const redirectThemes = () => {
    onResetTheme()
    navigate("/classement/themes")
    window.parent.postMessage({
      info:"redirect_theme",
      value:"list_pagetitle"
    }, "*")
  }
  
  return (
    <>
      {/**/}
      <Loader isOpen={loaderStatus} />

      {/**/}
      <Header isDisabled={!isAbleToSubmit} onMainPage={false}  isEdit={checkPath("addTheme") ? false:true} title={checkPath("addTheme") ? t("classement.Addatheme") : t("classement.editatheme")} arrowBackAction={() => { redirectThemes() }} secondBtnTitle={t("classement.Cancel")} CancelBtnAction={() => { redirectThemes() }} thirdBtnTitle={t("Profile.register")}
        addBtnAction={() => {
          checkPath("addTheme") ?
            handleCreateTheme()
            :
            editTheme();
          dispatch(setAddEditLoader(true))
        }}
      />
      <div className="divCard" >
        <div >
          <div className={"upload-imgUnique "}>
            <FormControl>

              <VignetteUpload />

            </FormControl>
            <div className="maximum_upload_size">
            <span> ( {t("classement.maximum_thumbnail_size")} </span>
            </div>
            <div className="acceptedDimenssion" >
            <span>{t("classement.extensions_accepted")}</span>
            <span>JPEG, PNG, APNG, AVIF, GIF, SVG, WebP</span>
             <span>{t("classement.dimensions_accepted")}</span>
             <span>480p (SD) : 720 x 480 </span>
             <span>720p (HD) : 1280 x 720</span>
             <span >1080p (HD) : 1920 x 1080 ) </span>
            </div>
          </div>
          {/* ajouter them formulare  */}
          <Formik
            initialValues={initalValues}
            validationSchema={object({
              classifiedBy: string().required("Please enter video Order"),
              WebtvChannelIDs: string().required("please enter channels")
            })}
            onSubmit={(values: any, formikHelpers: any) => {
              formikHelpers.resetForm();
            }}>
            {({ errors, touched }) => (

              <Form style={{ paddingRight: "10px",marginBottom:"1.5rem" }} >
                <div className={"InputLablDiv"}>
                  <InputText
                    labelInput={`${t("classement.Title")}`}
                    required
                    placeholder={`${t("classement.Title")}`}
                    value={values.Title}
                    handleChange={handleCreateNewTitle}
                    className={"formItem3"}
                    style={{ width: "100%" }}
                  ></InputText>
                </div>
                <div className={"InputLablDiv"}>
                  <SelectMultiple
                    label={`${t("classement.canals")}`}
                    required

                    className={"formItem3 multiselect"}
                    listIds={values.WebtvChannelIDs}
                    dataList={data && data?.map((el: any) => ({ Id: el?.Id, label: el?.Title }))}
                    placeHolder={`${t("classement.canals")}`}
                    handleChange={(e: any) => {
                      onValueFormUserchangeTheme(e.target.value, 'WebtvChannelIDs')
                    }}
                  />
                </div>
                <div className={"InputLablDiv"}>
                  <SelectList
                  labelText={`${t("classement.parenttheme")}`}
                  className={"formItem3"}
                  value={values.Parent_id || 0}
                  defaultValue={values.Parent_id || 0}
                  handleChange={(e: any) => {
                      handleCreateNewTheme(e);
                      onValueFormUserchangeTheme(e.target.value, 'Parent_id');
                  }}
                    data={themes.edges && themes.edges.map(({ node }: any) => ({ value: node.Id, label: node.Title }))}
                  >
                    <MenuItem value={0} > <span className="placeholder-color">{t("classement.Aucun")}</span></MenuItem>
              </SelectList>
                </div>
                <div className={"InputLablDiv"}>
                  <TextArea
                    labelInput={`${t("classement.Description")}`}
                    row={3}
                    maxRows={3}
                    className="formItem3 bitinput-style"
                    placeholder={`${t("classement.Description")}`}
                    handleChange={handleCreateNewDescription}
                    value={values.description}
                    id={"Description"}
                    required
                  />
                </div>

                <div className={"InputLablDiv"}>
                  <SelectList
                    labelText={`${t("classement.Visibility")}`}
                    className={"formItem3"}
                    value={values.Visible ? "1" : "0"}
                    defaultValue={values.Visible ? "1" : "0"}
                    handleChange={(e: any) => {
                      { handleCreateVisibility(e) }
                      onValueFormUserchangeTheme(Boolean(e.target.value === "1" ? true : false), 'Visible' ?? " ")
                    }}
                  >
                    <MenuItem value={"1"}>{t("classement.Yes")}</MenuItem>
                    <MenuItem value={"0"}>{t("classement.No")}</MenuItem>
                  </SelectList>
                </div>
                <div className={"InputLablDiv"}>
                  <SelectList
                    labelText={`${t("classement.Orderofvideos")}`}
                    className={"formItem3"}
                    value={values.classifiedBy}
                    defaultValue={values.classifiedBy}
                    handleChange={(e: any) => {
                      onValueFormUserchangeTheme(e.target.value, 'classifiedBy')
                    }}
                  >
                    <MenuItem value="VideoDate">{t("classement.CreationDate")}</MenuItem>
                    <MenuItem value="title">{t("classement.Alphabetical order")}</MenuItem>
                  </SelectList>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
export default AddEditTheme
