import { TYPES } from "./types";
import axios from "axios";

const search = window.location.search;
const token = new URLSearchParams(search).get("token");

const headers = {
  Authorization: `Bearer ${token}`,
};

const url = window.process.env.API_HOST_GLOBAL;
const urlVideo = window.process.env.VIDEO_API;
const newUrl = urlVideo ? urlVideo : url;
export const getUserData = () => async (dispatch) => {
  await axios
    .get(`${newUrl}/core/rights/get-user-rights`, { headers })
    .then((res) => {
      dispatch({ type: TYPES.GET_USER_DATA, payload: res.data });
      if (res?.data?.userData?.roles?.includes("ROLE_SUPER_ADMIN")) {
        dispatch({ type: TYPES.IS_SUPER_ADMIN });
      }
    })
    .catch(() => dispatch({ type: TYPES.UNAUTHORIZED }));
};
