import axios from "axios";
const search = window.location.search;
const token = new URLSearchParams(search).get("token");
const headers = {
    Authorization: `Bearer ${token}`,
  }
const url ="https:"+window.process.env.API_HOST_GLOBAL;

export const SendAccess = async (path, sessionId, action) => {
    try {
      return await axios.post(`${url}${path}`, {
        EmpStatsQuery:{
          session_id: sessionId,
          action: action,
        }
   
      },{headers});
    } catch (e) {
      console.error("ERROR Send access :", e);
    }
  };