import Cookies from "js-cookie";
import { SendAccess } from "../APIcals";
export const Helpers = () => {
	const guidGenerator = () => {
		let S4 = function () {
			return Math.random().toString(36).substring(2, 11);
		};
		return "_" + S4() + S4() + S4() + S4();
	};


	const acces_send = async (action) => {
		try {
			let sessionId = Cookies.get("session_acces");
			if (!sessionId) {
				sessionId = guidGenerator();
				let currentDate = new Date();
				let expireDate = new Date(currentDate.getTime() + 20 * 60 * 1000);
				Cookies.set("session_acces", sessionId, { expires: expireDate });
			}
			await SendAccess("/stat/acces_send", sessionId, action);
		} catch (error) {
			console.error("Error send Access API : :", error);
		}
	};

	return {
		acces_send,
	};
};
