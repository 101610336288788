
import { Label } from "@empreinte/components.label";
import { ModalConfirm } from '@empreinte/components.modal-confirm';
import { ModalInputs } from '@empreinte/components.modal-inputs';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Tooltip } from '@mui/material';
import Chip from '@mui/material/Chip';
import Menu from "@mui/material/Menu";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";
import Hooks from "../../utils/hooks";


import { Helpers } from '../../../../utils/helpers';
import "../../canal.scss";

function useActionMenu({ record }: any) {
  const [lang, setLang] = useState("");
  const [readingPr, setReadingPr] = useState("");
  const [mbr, setmbr] = useState("");
  const [cdn, setSDN] = useState("");
  const [pushNotif, setPushNotif] = useState("");
  const [vbr, setvbr] = useState("");
  const {acces_send} = Helpers()
  const [size, setSize] = useState(0);
  
  
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);



  
  useEffect(() => {
    if (record.is_vbr === true) {
      setvbr(`${t("classement.Yes")}`)
    }
    if (record.is_vbr === false) {
      setvbr(`${t("classement.No")}`)
    }
    if (record.SendPushNotification === true) {
      setPushNotif(`${t("classement.Yes")}`)
    }
    if (record.SendPushNotification === false) {
      setPushNotif(`${t("classement.No")}`)
    }
    if (record.Language === "fr") {
      setLang("Français");
    }
    if (record.Language === "en") {
      setLang("Anglais")
    }
    if (record.Language === "ar") {
      setLang("Arab")
    }
    if (record.Language === "") {
      setLang("")
    }
    if (record?.ChannelConfigId?.ReadingProtocol === "-1") {
      setReadingPr(`${t("classement.Usedefaultconfiguration")}`)
    }
    if (record?.ChannelConfigId?.ReadingProtocol === "RTMP") {
      setReadingPr("RTMP")
    }
    if (record?.ChannelConfigId?.ReadingProtocol === "HDS") {
      setReadingPr("HDS")
    }
    if (record?.ChannelConfigId?.IsCdn === true) {
      setSDN(`${t("classement.Yes")}`)
    }
    if (record?.ChannelConfigId?.IsCdn === false) {
      setSDN(`${t("classement.No")}`)
    }
    if (record?.ChannelConfigId?.ShowMbr === -1) {
      setmbr(`${t("classement.Usedefaultconfiguration")}`)
    }
    if (record?.ChannelConfigId?.ShowMbr === 0) {
      setmbr(`${t("classement.Disable")}`)
    }
    if (record?.ChannelConfigId?.ShowMbr === 1) {
      setmbr(`${t("classement.Enable")}`)
    }

  }, [record.SendPushNotification, record.is_vbr, record?.ChannelConfigId?.ShowMbr, record?.ChannelConfigId?.IsCdn, record?.ChannelConfigId?.ReadingProtocol, record.Language, record?.ChannelConfigId?.ReadingProtocol])

  const isSuperAdmin = useAppSelector((state: any) => state.userReducer.isSuperAdmin)

  const openSnackbar = useAppSelector((state: any) => state.CanalReducer.openSnackbar);
  const modules = useAppSelector((state: any) => state.userReducer.modules)

  const [clicked, setClicked] = useState<boolean>(false);

  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  const { handleShowModal, visible,
    handleCancel, handleShowModalExport,
    visibleExport, handleCancelModalExport,
    CopyUrlAuditeur, handleEditCanal, DeleteChannel, visibleDeletePopup, handleShowModalDeletePopup, handleCancelDeletePopup, loading } = Hooks()
  // urlExportChannel
  const urlExportChannel = useAppSelector((state: any) => state.CanalReducer.urlExportChannel)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);



  const setCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }
  useEffect(() => {
    setClicked(false)
  }, [openSnackbar])
  let servers = record?.servers?.map((s: any) => {
    return (
      <Chip label={s} />
    )
  })
  return (
    <div>
      <div className="action"  >

        {/* {modules?.parameters_canal_get_url_export &&
          <div has-permission="parameters_canal_get_url_export" >
            <Tooltip placement="top" title={`${t("classement.exportlink")}`}>
              <LinkIcon color="action" fontSize="small"
                onClick={() => handleShowModalExport(record.Id)} />
            </Tooltip>
          </div>} */}
 
            {size > 600 ? ( <>
              {(modules?.parameters_canal_post_edit || isSuperAdmin) &&
                <div has-permission="parameters_canal_post_edit"  >
                  <Tooltip placement="top" title={`${t("Profile.edit")}`}>
                    <ModeEditIcon color="action" fontSize="small"
                      onClick={() => {handleEditCanal(record);acces_send('parameters_canal_post_edit_action')}} />
                  </Tooltip>
                </div>}
              {(modules?.parameters_canal_get_detail || isSuperAdmin) &&
                <div has-permission="parameters_canal_get_detail" >
                  <Tooltip placement="top" title={`${t("Profile.details")}`}>
                    <RemoveRedEyeIcon color="action" fontSize="small"
                      onClick={()=>{handleShowModal();acces_send('parameters_canal_get_detail_action')}} />
                  </Tooltip>
                </div>}
              {(modules?.parameters_canal_delete_channel || isSuperAdmin) &&
                <div has-permission="parameters_canal_delete_channel" >
                  <Tooltip placement="top" title={`${t("Profile.delete")}`}><DeleteIcon fontSize="small" color="action" onClick={() => { handleShowModalDeletePopup() }} className={"icon_action"} /></Tooltip>
                </div>}
          </> ):(
             <> 
              <Label>
                <MoreVertIcon onClick={handleClick} />
              </Label> 
             
               <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <div className='lineActions'> 
               
                {(modules?.parameters_canal_post_edit || isSuperAdmin) &&
                <div has-permission="parameters_canal_post_edit"  className='lineActions-item'  >
                  <Tooltip placement="top" title={`${t("Profile.edit")}`}>
                    <ModeEditIcon color="action" fontSize="small"
                      onClick={() => {handleEditCanal(record);acces_send('parameters_canal_post_edit_action')} } />
                  </Tooltip>
                </div>}
                
                {(modules?.parameters_canal_get_detail || isSuperAdmin) &&
                  <div has-permission="parameters_canal_get_detail "  className='lineActions-item' >
                    <Tooltip placement="top" title={`${t("Profile.details")}`}>
                      <RemoveRedEyeIcon color="action" fontSize="small"
                        onClick={()=>{handleShowModal();acces_send('parameters_canal_get_detail_action')}} />
                    </Tooltip>
                  </div>}
               
                {(modules?.parameters_canal_delete_channel || isSuperAdmin) &&
                  <div has-permission="parameters_canal_delete_channel"  className="lineActions-item"  >
                    <Tooltip placement="top" title={`${t("Profile.delete")}`}><DeleteIcon fontSize="small" color="action" onClick={() => { handleShowModalDeletePopup() }} className={"icon_action"} /></Tooltip>
                  </div>}
                </div>
              </Menu>


            </>
          )}
      
        {/*Modal Details*/}
        <ModalInputs
          maxWidth="sm"
          onClose={handleCancel}
          dividers={true}
          aria-labelledby="customized-dialog-title"
          open={visible}
          title={`${t("classement.Channeldetails")} " ${record.Title} "`}
          CancelBtnName={`${t("Profile.close")}`}
          onCancel={handleCancel}
          // noFooter
          children={
            <div className="div_Url_diffusion">

              <div className={"detailsUserDivGlobal"}>
                <div className={"detailsUserDiv"}>
                  <span className={"detailsUserDiv_spn1"}>{t("classement.identifier")}</span>
                  <span className={"detailsUserDiv_spn2"}>{record.Id}</span>
                </div>
                <div className={"detailsUserDiv"}>
                  <span className={"detailsUserDiv_spn1"}>{t("classement.Title")}</span>
                  <span className={"detailsUserDiv_spn2"}>{record.Title}</span>
                </div>
                <div className={"detailsUserDiv"}>
                  <span className={"detailsUserDiv_spn1"}>{t("classement.Description")}</span>
                  {
                    record.Description ?
                      <span className={"detailsUserDiv_spn2"}>{record.Description}</span>
                      :
                      <span className={"detailsUserDiv_spn2"}>{t("classement.Aucun")}</span>

                  }
                </div>

                <div className={"detailsUserDiv"}>
                  <strong className={"detailsUserDiv_spn1"}>{t("classement.Language")}</strong>
                  {
                    record.Language ?
                      <span className={"detailsUserDiv_spn2"}>{record.Language}</span>
                      :
                      <span className={"detailsUserDiv_spn2"}>{t("classement.Aucun")}</span>

                  }
                </div>
                <div className={"detailsUserDiv"}>
                  <span className={"detailsUserDiv_spn1"}>{t("classement.Quotas")} (Mo)</span>
                  <span className={"detailsUserDiv_spn2"}>{record.Quota}</span>
                </div>
              </div>
            </div>
          }
        />


        <ModalConfirm
          open={visibleDeletePopup}
          onClose={handleCancelDeletePopup}
          title={`${t("Profile.DeleteMsgConfirmChannel")}`}
          content={`${record.Title} ${t("Profile.DeleteTitle")}`}
          CancelBtnName={`${t("Profile.close")}`}
          ConfirmBtnName={`${t("Profile.confirm")}`}
          onConfirm={() => { acces_send("parameters_canal_delete_channel_action");setClicked(true); DeleteChannel(record.Id) }}
          onCancel={handleCancelDeletePopup}
        />
      </div>
    </div>
  );
}

export default useActionMenu;
