import React, { useEffect, useState } from "react";
import {
  Card,
  MenuItem,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import "../../canal.scss";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import { useAppSelector } from "../../../../redux/hooks";
import { SnackBar } from "../../../../utils/SnackBar";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useLocation } from "react-router-dom";
import {
  setAddCanalActions,
  setEditChannelConfData,
  setEditChannelFormData,
  setOnAddEditLoader,
  setOpenSnackBar,
  setSubmit,
} from "../../store/canalActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GraphQLFetchData } from "../../utils/graphQLFetchData";
import { TextArea } from "@empreinte/components.text-area";
import { InputText } from '@empreinte/components.input-text'
import Header from "../../../../components/Header";
import { useNavigate } from "react-router-dom";
import { SelectMultiple } from '@empreinte/components.select-multiple'
import { Loader } from "@empreinte/components.loader"
import { SelectList } from "@empreinte/components.select-list"

const AddEditCanal = () => {
  const navigate = useNavigate();
  const { onResetCanal, onFinishAddCanal, onFinishEDitCanal } = Hooks();
  const redirectChannel = () => {
    onResetCanal();
    navigate("/classement/channels")
  }
  const dispatch = useDispatch();
  const openSnackbar = useAppSelector(
    (state: any) => state.CanalReducer.openSnackbar
  );
  const CanalConstraintsData = useAppSelector(
    (state: any) => state.CanalReducer.addCanalConstraintsData
  );
  const { Title, quota } = useAppSelector(
    (state: any) => state.CanalReducer.addCanalConstraintsData
  );
  const { t } = useTranslation();
  const { onValueFormUserchangeCanal, getServers } = Hooks();
  const { getChannelById } = GraphQLFetchData();
  const servers = useAppSelector((state: any) => state.CanalReducer.Servers);
  const editData = useAppSelector((state: any) => state.CanalReducer.editData);
  const editchannelConfigData = useAppSelector(
    (state: any) => state.CanalReducer.editchannelConfData
  );
  const editDataForm = useAppSelector(
    (state: any) => state.CanalReducer.editDataForm
  );
  const isAbleToSubmit = useSelector(
    (state: any) => state.CanalReducer.isAbleToSubmit
  );
  const { action, id } = useParams();
  const [count, setCount] = useState(0);
  useEffect(() => {
    dispatch(setOpenSnackBar({ open: false, message: "" }));
  }, []);

  useEffect(() => {
    if (
      !CanalConstraintsData.quota &&
      CanalConstraintsData.Title?.length > 0
    ) {
      dispatch(setSubmit(true));
    }
    if (!CanalConstraintsData.quota && CanalConstraintsData.Title?.length < 1) {
      dispatch(setSubmit(false));
    }
  }, [CanalConstraintsData.Title, CanalConstraintsData.quota]);

  useEffect(() => {
    if (CanalConstraintsData.reaction === true) {
      setCount((count) => count + 1);
      dispatch(
        setAddCanalActions({
          addCanalConstraintsDataNameChange: "reaction",
          addCanalConstraintsDataValueChange: false,
        })
      );
      setFermerSnack([-1, false]);
    }
  }, [CanalConstraintsData.reaction === true]);
  useEffect(() => {
    getServers();
    if (id && !Number.isNaN(+id)) {
      getChannelById({ variables: { id: +id } });
      dispatch(setEditChannelConfData({ ...editchannelConfigData, Id: id }));
      const {
        Title,
        is_vbr,
        Description,
        EmbedVideo,
        PermalienVideo,
        Language,
        Quota,
        SendPushNotification,
        Upload_Path,
        Upload_Url,
      } = editData;
      dispatch(
        setEditChannelFormData({
          ...editDataForm,
          is_vbr: is_vbr,
          Uid: "",
          PermalienVideo: PermalienVideo,
          Description: Description,
          Title: Title,
          Quota: Quota,
          Language: Language,
          SendPushNotification: SendPushNotification,
          Upload_Path: Upload_Path,
          Id: id,
          ChannelConfigIdID: editData?.ChannelConfigId?.Id,
          EmbedVideo: EmbedVideo,
          Upload_Url: Upload_Url,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (editDataForm.Quota >= 0 && editDataForm.Title?.length > 0) {
      dispatch(setSubmit(true));
    }
    if (editDataForm.Title?.length < 1) {
      dispatch(setSubmit(false));
    }
  }, [editDataForm.Quota, editDataForm.Title?.length]);

  useEffect(() => {
    servers.filter((row: any) => row.node.Name !== "");
  }, [servers]);
  const handleChange = (event: any) => {
    dispatch(
      setEditChannelFormData({
        ...editDataForm,
        ServersIds: event.target.value ? [...event.target.value] : [],
      })
    );
  };
  const handleChangeConfData = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const valeur = event.target.value;
    dispatch(
      setEditChannelConfData({
        ...editchannelConfigData,
        [name]: valeur,
      })
    );
  }
  const options_languages = [
    {
      label: t("classement.French"),
      name: "Language",
      value: "fr",
      color: "rgb(230, 247, 255)",
      fontWeight: "bold",
    },
    {
      label: t("classement.English"),
      name: "Language",
      value: "en",
      color: "white",
      fontWeight: "normal",
    },
    {
      label: t("classement.Arab"),
      name: "Language",
      value: "ar",
      color: "white",
      fontWeight: "normal",
    },
  ];
  const options_YesOrNo = [
    {
      label: "Non",
      name: "IsCdn",
      value: false,
      color: "rgb(230, 247, 255)",
      fontWeight: "bold",
    },
    {
      label: "Oui",
      name: "IsCdn",
      value: true,
      color: "white",
      fontWeight: "normal",
    },
  ];
  const [open, setOpen] = useState([
    { num_cham: "ch0", etat: false },
    { num_cham: "ch1", etat: false },
    { num_cham: "ch2", etat: false },
    { num_cham: "ch3", etat: false },
    { num_cham: "ch4", etat: false },
  ]);
  const updateFieldChanged = (index: number, demande: string) => {
    let newArr = [...open];
    if (demande === "op") {
      newArr[index].etat = true;
    } else {
      newArr[index].etat = false;
    }
    setOpen(newArr);
  };
  const handleOpen = (ch: string) => {
    open.forEach((item, index) => {
      if (item.num_cham === ch) {
        updateFieldChanged(index, "op");
      }
    });
  };
  const handleClose = (ch: string) => {
    open.forEach((item, index) => {
      if (item.num_cham === ch) {
        updateFieldChanged(index, "cl");
      }
    });
  };
  const loaderStatus = useSelector(
    (state: any) => state.CanalReducer.isDoneAddEdit
  );

  const [fermerSnack, setFermerSnack] = useState([-1, false]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const location = useLocation();
  const checkPath = (pageInfo: string) => {
    return location.pathname.toLowerCase().includes(pageInfo.toLowerCase())
  }

  return (
    <>
      {/**/}
      <Loader isOpen={loaderStatus} />

      {/**/}
      <Header onMainPage={false} isDisabled={!isAbleToSubmit} arrowBackAction={() => { redirectChannel() }}             isEdit={checkPath("addChannel") ? false:true}
 title={checkPath("addChannel") ? t("classement.Addachannel") : t("classement.editchannel") } secondBtnTitle={t("classement.Cancel")} CancelBtnAction={() => { redirectChannel() }} thirdBtnTitle={t("Profile.register")} addBtnAction={() => {
        checkPath("addChannel") ?
          onFinishAddCanal()
          :
          onFinishEDitCanal();
        dispatch(setOnAddEditLoader(true));
      }} />
      <div className="bg_datatable">
        <div className="datable_marginall">
          <div className={"divAddEditCanal"}>
            {openSnackbar.open && <SnackBar />}
            <Card className={"card1"}>
              <div className={"InputLablDiv"}>
                <InputText
                  labelInput={`${t("classement.Title")}`}
                  required
                  placeholder={`${t("classement.Title")}`}
                  id={"Title"}
                  value={editDataForm?.Title}
                  handleChange={(event) => {
                    const valeur = event.target.value;
                    action === "addChannel"
                      ? onValueFormUserchangeCanal(["Title", valeur])
                      : dispatch(
                        setEditChannelFormData({
                          ...editDataForm,
                          Title: valeur,
                        })
                      );
                  }}
                  className={"formItem3"}
                  style={{ width: "100%" }}
                ></InputText>
              </div>
              <div className={"InputLablDiv"}>
                <TextArea
                  labelInput={`${t("classement.Description")}`}
                  row={3}
                  maxRows={3}
                  className="formItem3 bitinput-style"
                  placeholder={`${t("classement.Description")}`}
                  handleChange={(event) => {
                    const valeur = event.target.value;
                    action === "addChannel"
                      ? onValueFormUserchangeCanal(["Description", valeur])
                      : dispatch(
                        setEditChannelFormData({
                          ...editDataForm,
                          Description: valeur,
                        })
                      );
                  }}
                  value={editDataForm?.Description}
                  id={"Description"}
                />
              </div>
              <div className={"InputLablDivSelect"}>
                <div className={" InputLablDiv-multi"}>
                  <SelectList
                    labelText={`${t("classement.Language")}`}
                    style={{ width: "100%" }}
                    value={editDataForm?.Language || "0"}
                    defaultValue={"0"}
                    className={"formItem3"}

                    handleChange={(e: SelectChangeEvent<string>) => {
                      dispatch(
                        setEditChannelFormData({
                          ...editDataForm,
                          Language: e.target.value === "0" ? "" : e.target.value,
                        })
                      )
                    }}
                    data={options_languages}
                  >
                    <MenuItem value="0">
                      <span className="placeholder-color">
                        {t("classement.Language")}
                      </span>
                    </MenuItem>
                  </SelectList>
                </div>
              </div>
              <div className={"InputLablDiv"}>
                <InputText
                  type="number"
                  labelInput={`${t("classement.Quotas")}`}
                  required
                  placeholder={`${t("classement.Quotas")}`}
                  value={!editDataForm?.Quota ? CanalConstraintsData?.quota: editDataForm?.Quota}
                  handleChange={(event) => {
                    const valeur = event.target.value;
                    action === "addChannel"
                      ? onValueFormUserchangeCanal(["Quota", valeur])
                      : dispatch(
                        setEditChannelFormData({
                          ...editDataForm,
                          Quota: valeur ? valeur : CanalConstraintsData?.quota,
                        })
                      );
                  }}
                  className={"formItem3"}
                  style={{ width: "100%" }}
                ></InputText>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddEditCanal;
