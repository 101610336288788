import { useLocation } from "react-router-dom";
import { Header as CommonHeader } from "@empreinte/components.header"
import { useAppSelector } from "../redux/hooks";
import "../index.scss"
const Header = ({ onMainPage, isDisabled, arrowBackAction,isEdit, title, mainPageBtnAction, firstBtnTitle, firstBtnColor, CancelBtnAction, secondBtnTitle, secondBtnColor, thirdBtnColor, thirdBtnTitle, addBtnAction }: any) => {
    const location = useLocation();
    const modules = useAppSelector((state: any) => state.userReducer.modules);
    const isSuperAdmin = useAppSelector((state: any) => state.userReducer.isSuperAdmin)
    const permission =(location.pathname.includes("/theme") ? (isEdit?modules?.parameters_theme_post_edit:modules?.parameters_theme_post_add) : (isEdit?modules?.parameters_canal_post_edit:modules?.parameters_canal_post_add)) || isSuperAdmin
    if (!location.pathname.includes("/channel") && !location.pathname.includes("/theme")) {
        return null
    }
    return (
        <div>
            <CommonHeader isDisabled={isDisabled} title={title} onMainPage={onMainPage} hasPermission={`${permission === true ? "true" : ""}`} arrowBackAction={arrowBackAction} mainPageBtnAction={mainPageBtnAction} firstBtnTitle={firstBtnTitle} firstBtnColor={firstBtnColor} CancelBtnAction={CancelBtnAction} secondBtnTitle={secondBtnTitle} secondBtnColor={secondBtnColor} thirdBtnColor={thirdBtnColor} thirdBtnTitle={thirdBtnTitle} addBtnAction={addBtnAction} />
        </div>
    )
}
export default Header