import { useState ,useLayoutEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip, useMediaQuery } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Hooks } from "../../utils/hooks";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LinksIcon from '../AddEdit/LinksIcon';
import { useDispatch, useSelector } from 'react-redux';
import { SetModalDisplay, setTitle } from '../../store/themeActions';
import "../../../../index.scss"
import { ModalInputs } from '@empreinte/components.modal-inputs';
import { ModalConfirm } from '@empreinte/components.modal-confirm'
import "../../theme.scss"
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Label } from "@empreinte/components.label";
import Menu from "@mui/material/Menu";
import { Helpers } from '../../../../utils/helpers';
function useActionMenu({ record }: any) {
    const LinksData = useSelector((state: any) => state.ThemeReducer.linksThemes)
    const modules = useSelector((state: any) => state.userReducer.modules)
    const isSuperAdmin = useSelector((state: any) => state.userReducer.isSuperAdmin)
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {acces_send} = Helpers()
    const [handleOpen, setHandleOpen] = useState(false)
    const { visible, linksExport, showModal, handleCancel, onDeleteTheme, handleShowExportModal, visibleExport, handleCancelModalExport, CopyUrlParticipant, CopyUrlAuditeur, CopyLienIntegration
        , visibleDeletePopup, handleCancelDeletePopup, showModalDeletePopup, loading } = Hooks()
    //linksThemes
    const dispatch = useDispatch();
    const linksThemes = useSelector((state: any) => state.CanalReducer.linksThemes)
    const title = useSelector((state: any) => state.ThemeReducer.title)
    const [clicked, setClicked] = useState<boolean>(false)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleEditTheme = () => { 
        navigate(`/classement/theme/${record?.Id}/editTheme`)
        window.parent.postMessage({
            info:"redirect_theme",
            value:"edit_pagetitle"
        }, "*")
     }
     const [size, setSize] = useState(0);
     useLayoutEffect(() => {
       function updateSize() {
         setSize(window.innerWidth);
       }
       window.addEventListener('resize', updateSize);
       updateSize();
       return () => window.removeEventListener('resize', updateSize);
     }, []);
     const [anchorEl, setAnchorEl] = useState(null);
     const open = Boolean(anchorEl);
     const handleClick = (event: any) => {
       setAnchorEl(event.currentTarget);
     };
     const handleClose = () => setAnchorEl(null);

    return (
        <div>
            <div className="action" style={{ display: "flex", justifyContent: "flex-start" }}>
                <div style={{ marginRight: "16px" }} >
                </div>
            {size > 600 ? (  
                <>
                 {(modules?.parameters_theme_get_export || isSuperAdmin) &&
                    <div has-permission="parameters_theme_get_export" >
                        <Tooltip placement="top" title={t("classement.linkUrl")} >
                            {
                                record.Visible ?
                                    <InsertLinkIcon onClick={() => { setHandleOpen(true); dispatch(setTitle(record.Title)); handleShowExportModal(record.Id,"parameters_theme_get_export_action"); }} color="action" fontSize="small" className={"icon_action"} />
                                    :
                                    <div ></div>
                            }
                        </Tooltip>

                    </div>
                    }
                     {(modules?.parameters_theme_post_edit || isSuperAdmin) &&
                     <div has-permission="parameters_theme_post_edit">
                        <Tooltip placement="top" title={`${t("Profile.edit")}`} >
                            <ModeEditIcon color="action" fontSize="small" className={"icon_action"} onClick={()=>{handleEditTheme();acces_send("parameters_theme_post_edit_action")}} />
                        </Tooltip>
                    </div>
                    }
                     {(modules?.parameters_theme_get_detail || isSuperAdmin) &&
                    <div has-permission="parameters_theme_get_detail">
                        <Tooltip placement="top" title={`${t("Profile.details")}`}>
                            <RemoveRedEyeIcon color="action" fontSize="small" className={"icon_action"} onClick={()=>{showModal();acces_send("parameters_theme_get_detail_action")}} />
                        </Tooltip>
                    </div>
                    }
                    {(modules?.parameters_theme_delete_theme || isSuperAdmin) &&
                    <div has-permission="parameters_theme_delete_theme" >
                        <Tooltip placement="top" title={`${t("Profile.delete")}`}>
                            <DeleteIcon color="action" fontSize="small" onClick={showModalDeletePopup} className={"icon_action"} />
                        </Tooltip>
                    </div>
                    }
                </>


            ):(
                <>
                <Label>
                <MoreVertIcon onClick={handleClick} />
              </Label>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <div className='lineActions'>
              {(modules?.parameters_theme_get_export || isSuperAdmin) &&
                    <div has-permission="parameters_theme_get_export" className='actionbtns' >
                        <Tooltip placement="top" title={t("classement.linkUrl")} >
                            {
                                record.Visible ?
                                    <InsertLinkIcon onClick={() => { setHandleOpen(true); dispatch(setTitle(record.Title)); handleShowExportModal(record.Id,"parameters_theme_get_export_action"); }} color="action" fontSize="small" className={"icon_action"} />
                                    :
                                    <div ></div>
                            }
                        </Tooltip>

                    </div>
                    }
                     {(modules?.parameters_theme_post_edit || isSuperAdmin) &&
                     <div has-permission="parameters_theme_post_edit" className='actionbtns'>
                        <Tooltip placement="top" title={`${t("Profile.edit")}`} >
                            <ModeEditIcon color="action" fontSize="small" className={"icon_action"} onClick={()=>{handleEditTheme();acces_send("parameters_theme_post_edit_action")}}  />
                        </Tooltip>
                    </div>
                    }
                     {(modules?.parameters_theme_get_detail || isSuperAdmin) &&
                    <div has-permission="parameters_theme_get_detail" className='actionbtns'>
                        <Tooltip placement="top" title={`${t("Profile.details")}`}>
                            <RemoveRedEyeIcon color="action" fontSize="small" className={"icon_action"} onClick={()=>{showModal();acces_send("parameters_theme_get_detail_action")}} />
                        </Tooltip>
                    </div>
                    }
                    {(modules?.parameters_theme_delete_theme || isSuperAdmin) &&
                    <div has-permission="parameters_theme_delete_theme" className='actionbtns' >
                        <Tooltip placement="top" title={`${t("Profile.delete")}`}>
                            <DeleteIcon color="action" fontSize="small" onClick={showModalDeletePopup} className={"icon_action"} />
                        </Tooltip>
                    </div>
                    }
                    </div>
              </Menu>
              </>
            )}
                {/*Modal Details*/}
                <ModalInputs
                    title={`${t("Profile.ThemeDetails")} " ${record.Title} "`}
                    maxWidth="sm"
                    dividers={true}
                    onClose={handleCancel}
                    aria-labelledby="customized-dialog-title"
                    open={visible}
                    children={
                        <div className={"themes-details-modal"}>
                            <div className={"themes-details-modal-container"}>
                                {
                                    record?.Logo ?
                                        <img className="logo" src={record?.Logo} alt="logo" />
                                        :
                                        null

                                }
                            </div>
                            <div className={"themes-details-modal-container"}>
                                <span className={"themes-details-modal-title"}>{`${t("classement.identifier")}`}</span>
                                <span className={"themes-details-modal-value"}>{record.Id}</span>
                            </div>
                            <div className={"themes-details-modal-container"}>
                                <span className={"themes-details-modal-title"}>{`${t("classement.parenttheme")}`}</span>
                                {
                                    record.Parent ?
                                        <span className={"themes-details-modal-value"}>{record.Parent}</span>
                                        :
                                        <span className={"themes-details-modal-value"}>- -</span>

                                }
                            </div>
                            <div className={"themes-details-modal-container"}>
                                <span className={"themes-details-modal-title"}>{`${t("classement.Title")}`}</span>
                                <span className={"themes-details-modal-value"}>{record.Title}</span>
                            </div>
                            <div className={"themes-details-modal-container"}>
                                <span className={"themes-details-modal-title"}>{`${t("classement.Alias")}`}</span>
                                {
                                    record.Alias ?
                                        <span className={"themes-details-modal-value"}>{record.Alias}</span>
                                        :
                                        <span className={"themes-details-modal-value"}>- -</span>

                                }
                            </div>
                            <div className={"themes-details-modal-container"}>
                                <span className={"themes-details-modal-title"}>{`${t("classement.Description")}`}</span>
                                <span className={"themes-details-modal-value"}>{record.description}</span>
                            </div>
                            <div className={"themes-details-modal-container"}>
                                <span className={"themes-details-modal-title"}> {`${t("classement.channel")}`}</span>
                                {/* <span className={"themes-details-modal-value"}>{record.channel}</span> */}
                                <span className={"themes-details-modal-value"} style={{ height: 'auto', display: 'flex', flexWrap: 'wrap', gap: '5.0px' }}>
                                    {
                                        record?.channel?.map((ch: any) => {
                                            return (
                                                <Chip label={ch} />


                                            )
                                        })
                                    }
                                </span>

                            </div>
                            <div className={"themes-details-modal-container"}>
                                <span className={"themes-details-modal-title"}>{`${t("classement.Directory")}`}</span>
                                {
                                    record.Directory ?
                                        <span className={"themes-details-modal-value"}>{record.Directory}</span>
                                        :
                                        <span className={"themes-details-modal-value"}>- -</span>

                                }
                            </div>
                            <div className={"themes-details-modal-container"}>
                                <span className={"themes-details-modal-title"}>{`${t("classement.Subdirectory")}`}</span>
                                {
                                    record.SubDirectory ?
                                        <span className={"themes-details-modal-value"}>{record.SubDirectory}</span>
                                        :
                                        <span className={"themes-details-modal-value"}>- -</span>

                                }
                            </div>
                            <div className={"themes-details-modal-container"}>
                                <span className={"themes-details-modal-title"}>{t("classement.Link")} </span>
                                {
                                    record.UrlName ?
                                        <span className={"themes-details-modal-value"}>{record.UrlName}</span>
                                        :
                                        <span className={"themes-details-modal-value"}>- -</span>

                                }
                            </div>
                            <div className={"themes-details-modal-container"}>
                                <span className={"themes-details-modal-title"}>{t("classement.Numberofviews")}</span>
                                <span className={"themes-details-modal-value"}>{record.Occurrences}</span>
                            </div>
                            <div className={"themes-details-modal-container"}>
                                <span className={"themes-details-modal-title"}>{t("classement.Visible")} </span>
                                <span className={"themes-details-modal-value"}>{record.Visible ? t("classement.Yes") : t("classement.No")}</span>
                            </div>
                            <div className={"themes-details-modal-container"}>
                                <span className={"themes-details-modal-title"}>{t("classement.Sorted by")}</span>
                                {record.Orderr ?

                                    <span className={"themes-details-modal-value"}>{record.Orderr}</span>
                                    :
                                    <span className={"themes-details-modal-value"}>- -</span>

                                }
                            </div>
                            <div className={"themes-details-modal-container"}>
                                <span className={"themes-details-modal-title"}>{t("classement.SortBy")}</span>
                                <span className={"themes-details-modal-value"}>{record.classifiedBy === "title" ? t("classement.Alphabetical order") : t("classement.CreationDate")}</span>
                            </div>

                        </div>
                    }
                    CancelBtnName={`${t("Profile.close")}`}
                    onCancel={handleCancel}
                />
                <ModalConfirm
                    fullWidth
                    open={visibleDeletePopup}
                    onClose={handleCancelDeletePopup}
                    title={`${t("Profile.DeleteOneMsgConfirmTheme")}`}
                    content={`${record.Title} ${t("Profile.DeleteTitle")}`}
                    CancelBtnName={`${t("classement.No")}`}
                    ConfirmBtnName={`${t("classement.Yes")}`}
                    onConfirm={() => { setClicked(true); onDeleteTheme(record.Id);acces_send("parameters_theme_delete_theme_action") }}
                    onCancel={handleCancelDeletePopup}
                />


                <LinksIcon title={title} codeEmbed={LinksData?.codeEmbed} url_export={LinksData?.UrlEmbed} integrationLink={LinksData?.UrlPermalien} isOpen={handleOpen} onClick={() => setHandleOpen(false)} />

            </div>
        </div>
    );
}

export default useActionMenu;